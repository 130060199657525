import React from "react";
import * as Styled from "./footerStyles";

const Footer=()=>{
    
    return (
			<>
				<Styled.MainDiv>
					<Styled.Container>
						<Styled.FooterLeft>
							<Styled.FooterH2>Customer Service Inquiries</Styled.FooterH2>
							701-852-0061
							<br />
							<Styled.FooterLink
								href="mailto:superpumper@spfenergy.com"
								target="_blank">
								superpumper@spfenergy.com
							</Styled.FooterLink>
						</Styled.FooterLeft>
						<Styled.FooterMiddle>
							<Styled.FooterH2>General Inquiries</Styled.FooterH2>
							<Styled.FooterMiddleContainer>
								<Styled.FooterMiddleLeft>
									701-852-0061
									<br />
									<Styled.FooterLink
										href="mailto:info@gosuperpumper.com"
										target="_blank">
										info@gosuperpumper.com
									</Styled.FooterLink>
								</Styled.FooterMiddleLeft>
								<div>
									100 27th Street NE
									<br /> Minot, ND 58703-5164
								</div>
							</Styled.FooterMiddleContainer>
						</Styled.FooterMiddle>
						<Styled.FooterRight>
							<Styled.FooterH2>© 2023 superpumper</Styled.FooterH2>
							<Styled.FooterLink
								href="http://www.parkland.ca/terms-use"
								target="_blank">
								Privacy Policy
							</Styled.FooterLink>
							<br />
						</Styled.FooterRight>
					</Styled.Container>
				</Styled.MainDiv>
			</>
		);
}

export default Footer;