import styled from 'styled-components';

export const HomePageContainer = styled.div`
    text-align: center;
    background-color: #fff;
    margin: 0;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: top center;
    color: #666;
    font-family: 'Gotham-Book', arial, sans-serif;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.8px;
`;

export const Header1 = styled.h1`
    padding: 0;
    font-weight: normal !important;
    color: #9b1b1f;
    font-size: 45px;
    line-height: 50px;
    text-transform: uppercase;
    display: block;
    font-family: 'Gotham-Book', arial, sans-serif !important;
    margin-bottom: 15px;

    @media (max-width: 767px) { 
      font-size: 23px;
 }
`;

export const Paragraph = styled.p`
    margin: 0 0 30px 0px;
    padding: 0;
    padding-left: 264.57px;
    padding-right: 264.57px;
    text-align: justify;
    width:100%;
    font-size: 15px;

 @media (max-width: 991px) { 
      padding-left: 37.7952755906px;
      padding-right: 37.7952755906px;
 }
`;

export const paraText = styled.p` 
    margin: 0 0 30px 0; 
    display: block;
    margin-block-start: 16px;
    margin-block-end: 16px; 
    color: #666;
    font-family: "Gotham-Book", arial, sans-serif;
    font-size: 15px; 
    line-height: 24px;
    letter-spacing: 0.8px;
`;

export const subParaText = styled.a`
    text-decoration: none;
    background: #f3f3f3;
    color: #ee1f23;
    font-family: "Gotham-Book", arial, sans-serif;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.8px;
    transition: color 0.15s ease-out;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
    transition-delay: 0s;
    transition-property: color;
    box-sizing: border-box;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.15);
    cursor: pointer!important;
    background-image: url(../images/homeBottom-item-bg.gif); 
    padding: 7px; 
    padding-bottom: 0px;
    :hover{color:#ee1f23;}
    `;
