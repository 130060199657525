import styled from 'styled-components';

export const MainContainer = styled.div`
  display: block;
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  padding: 0 15px 10px 0;
  text-align: center;
  transition: padding 0.15s ease-out;

  @media print{
    display:none;
  }
`;
export const ScrollAnchor = styled.a`
    display: inline;
    z-index: 999;
    bottom: 0;
    right: 0;
    padding: 0 15px 10px 0;
    text-align: center;
    transition: padding 0.15s ease-out;
`;

export const ScrollImage = styled.img`
  border: 0;
  vertical-align: top;
  width: 30px;
  height: auto;
  transition: 0.25s ease-out;
  cursor: pointer;
  :hover{
    padding-bottom: 10px;
  }
`;
