/** @format */

import { graphql, navigate } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import GlobalStyles from "../styles/globalStyles";
import HomePage from "../templates/homePageTemplate/homePageTemplate";

const RootIndex = ({ data, location }) => {
	const [loaded, setLoaded] = React.useState(false);

	const redirectArray = [
		"/careers/",
		"/station-directory/",
		"/about-superpumper/",
	];
	const serializeRoutes = (route) => {
		if (route?.slice(-1) !== "/" && !route.includes(".html")) {
			route = route + "/";
		}
		if (route?.slice(-1) === "/" && !route.includes(".html")) {
			route = route + "index.html";
		}
		return route;
	};

	const allSlug = data?.allSitePage?.nodes?.map(({ path }) =>
		serializeRoutes(path)
	);
	React.useEffect(() => {
		let route = location?.href;
		if (location?.href !== location?.href?.toLowerCase()) {
			route = location?.href?.toLowerCase();
		}
		if (redirectArray?.includes(location?.pathname)) {
			window.location.href = "/index.html";
		}
		route = serializeRoutes(route);
		if (
			(!route?.includes(".html") || !route?.includes("404")) &&
			allSlug?.includes(route)
		) {
			window.location.href = route;
		}
		setLoaded(true);
	}, []);

	return !loaded ? (
		<></>
	) : (
		<>
			<GlobalStyles />
			<Helmet>
				<meta charSet="utf-8" />
				<title>SuperPumper :: Home</title>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0"></meta>
				<meta name="format-detection" content="telephone=no"></meta>
				<meta
					name="description"
					content="Superpumper, Inc. is a growing regional convenience store with 24 locations throughout North Dakota , Montana, Wyoming and Minnesota. It was founded in 1983 by John Havnvik . In 1996, Superpumper, Inc. merged with Farstad Oil, Inc. to form SPF Energy, Inc. Currently, Superpumper is part of Parkland USA, after it was acquired by Parkland Corporation of Calgary, Alberta, Canada in 2014."></meta>
				<meta name="generator" content="WordPress 5.3.2"></meta>
			</Helmet>
			<HomePage />
		</>
	);
};

export default RootIndex;
export const pageQuery = graphql`
	query AllPagesQuery {
		allSitePage {
			nodes {
				path
			}
		}
	}
`;
