import * as React from 'react';
import * as Styled from './backToTheTopScrollStyle';
import IconArrowDown from '../../images/arrow-blue-backtotop.png';

const BackToTopScroll = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const topToScroll = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {scrollPosition > 0 && (
        <Styled.MainContainer>
          <Styled.ScrollAnchor onClick={() => topToScroll()}>
            <Styled.ScrollImage src={IconArrowDown} alt="Return To Top" title= 'Back to Top' />
          </Styled.ScrollAnchor>
        </Styled.MainContainer>
      )}
    </>
  );
};

export default BackToTopScroll;
