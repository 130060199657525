import styled from "styled-components";
import BackgroundImage from '../../images/foot-container-bg.jpg'

export const MainDiv = styled.div`
    background: #9b1b1f url(${BackgroundImage}) no-repeat top center;
    height: auto;
    font-family: 'Gotham-Book', arial, sans-serif;
    padding: 20px;
    text-align: left;

    @media (max-width: 760px) {
        text-align: center;
    }
`;

export const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 980px){
        width: 728px;
        font-size: 12px;
        line-height: 22px;
    }

    @media (max-width: 760px) {
        flex-direction:column;
        width:100%;
    }
`;

export const FooterLeft=styled.div`
    float: left;
    margin-right: 10%;

    @media (max-width: 1280px) {
        width: 270px;
        margin: 0 50px 0 0;
    }

    @media (max-width: 980px) {
        width: 220px;
        margin-right: 0;
    }

    @media (max-width: 760px) {
        width: 100%;
        margin: 0 0 25px 0;
    }
`;

export const FooterH2=styled.div`
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fffc00;

    @media (max-width: 760px) {
        margin-bottom: 20px;
        margin-top: 25px;
    }
`;

export const FooterLink = styled.a`
        color: #d5c408;
        text-decoration: none;
&:hover{
        color: #fff;
}
`;

export const FooterMiddle = styled.div`
    float: left;
    margin-right: auto;

    @media (max-width: 760px) {
        margin:0;
    }
`;

export const FooterMiddleContainer = styled.div`
    display: flex;

    @media (max-width: 1280px) {
        flex-direction:column;
    }
`;

export const FooterMiddleLeft=styled.span`
    margin-right: 20px;
`;

export const FooterRight=styled.div`
    float: right;
`;

export const BackToTop=styled.a`
    display: none;
    position: fixed;
    z-index: 999;
    bottom: 0;
    right: 0;
    padding: 0 15px 10px 0;
    text-align: center;
    -webkit-transition: padding 0.15s ease-out;
    -moz-transition: padding 0.15s ease-out;
    -o-transition: padding 0.15s ease-out;
    -ms-transition: padding 0.15s ease-out;
    transition: padding 0.15s ease-out;
`;

export const Image = styled.img`
    width: 30px;
    height: auto;
`;
