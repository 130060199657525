import * as React from 'react';
import * as Styled from './homePageTemplateStyle';
import Footer from '../../components/footer/footer';
import AccountTemplate from '../../components/navigationBar/accountTemplate/accountTemplate';
import BackToTopScroll from '../../components/backToTheTopScroll/backToTheTopScroll';

const HomePage = () => {
  return (
    <Styled.HomePageContainer>
      <div style={{ clear: 'both' }} />
      <AccountTemplate/>
      <Styled.Header1>ABOUT SUPERPUMPER</Styled.Header1> 
      <Styled.Paragraph>Superpumper, Inc. is a growing regional convenience store with 44 locations throughout North Dakota , Montana, and Minnesota. It was founded in 1983 by John Havnvik . 
        In 1996, Superpumper, Inc. merged with Farstad Oil, Inc. to form SPF Energy, Inc. Currently, Superpumper is part of Parkland USA, after it was acquired by Parkland Corporation of Calgary, Alberta, Canada in 2014.
      </Styled.Paragraph>
      <Styled.Paragraph>
        The Superpumper Corporate Office is located in north central North Dakota in the city of Minot. The market area is approximately 160,000 square miles with a population base of approximately 1.75 million people.
      </Styled.Paragraph>
      <Styled.Paragraph>
        Our continuing focus is to be the convenience store of choice for our customers and we are striving to becoming more involved in the communities we serve.
      </Styled.Paragraph>
      <Styled.Header1>CAREERS</Styled.Header1>
      <Styled.Paragraph>
        Superpumper, Inc. is a growing, family oriented company looking for professional team members who have initiative, a strong work ethic and a desire to be part of a company that strives to meet our customer’s needs.
      </Styled.Paragraph>
      <Styled.subParaText
        href= 'https://recruiting.ultipro.ca/PAR5001PFCN/JobBoard/99f20d85-b760-46cc-9bee-d07c58d641f0/?q=&o=postedDateDesc'
        target= '_blank'
      >
        Click here to view our current job openings and apply for positions online.
      </Styled.subParaText>
      <div style={{ clear: 'both' }} />
      <Styled.Paragraph/>
      <BackToTopScroll/>
      <Footer/>
    </Styled.HomePageContainer>
  );
};


export default HomePage;
